import { VApp } from 'vuetify/lib/components/VApp';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VApp,{class:{
    'app-admin-wrap-layout-1 sidebar-mini': _vm.getThemeMode.verticalSidebarMini,
    'sidebar-close': !_vm.getThemeMode.verticalSidebarDrawer,
  },style:({
    background: _vm.$vuetify.theme.themes[_vm.theme].background,
    color: _vm.$vuetify.theme.themes[_vm.theme].color,
  })},[_c('side-bar'),_c('app-bar',{}),_c('base-view')],1)}
var staticRenderFns = []

export { render, staticRenderFns }